import React from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';

interface UserMenuProps {
    anchorElUser: HTMLButtonElement | null;
    handleCloseUserMenu: () => void;
    handleLogout: () => void;
}
export const UserMenu = ({ anchorElUser, handleCloseUserMenu, handleLogout }: UserMenuProps) => {
    return (
        <Menu
            sx={{ mt: '45px' }}
            id='menu-appbar'
            anchorEl={anchorElUser}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
        >
            {/* Logout button */}
            <MenuItem onClick={handleLogout}>
                <Typography textAlign='center'>Logout</Typography>
            </MenuItem>
        </Menu>
    );
};
