import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    successMsg: {
        msg: '',
        id: 0,
    },
};

type State = typeof initialState;

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        notificationUpdated(state, action: PayloadAction<string>) {
            return {
                ...state,
                successMsg: {
                    msg: action.payload,
                    id: state.successMsg.id + 1,
                },
            };
        },
    },
});

export default notificationsSlice.reducer;

export const { notificationUpdated } = notificationsSlice.actions;

// SELECTORS
export const selectSuccessMsg = (state: State): SuccessMsg => state.successMsg;
