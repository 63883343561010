import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { Logo } from '../Logo/Logo';
import { AvatarWithTooltip, DesktopMenu, HamburgerMenuButton, MobileMenu, UserMenu } from './subcomponents';
import { useNavBar } from './useNavBar';

interface NavBarProps {
    appName: string;
    landingPage: string;
    pages: {
        name: string;
        path: string;
    }[];
}

export const NavBar = ({ appName, pages, landingPage }: NavBarProps) => {
    const {
        userName,
        userPicture,
        anchorElNav,
        anchorElUser,
        handleOpenNavMenu,
        handleNavMenuItem,
        handleCloseNavMenu,
        handleOpenUserMenu,
        handleCloseUserMenu,
        handleLogout,
    } = useNavBar();

    return (
        <AppBar position='sticky'>
            <Toolbar
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'row', md: 'row', sm: 'row' },
                    alignItems: 'center',
                    justifyContent: { xs: 'space-between ', md: 'row', sm: 'row' },
                    overflow: 'auto',
                    bgcolor: 'rgb(240,224,217)',
                }}
            >
                {/* Header */}
                <Link to={landingPage}>
                    <Logo />
                </Link>
                <Typography
                    variant='h6'
                    sx={{
                        padding: '0 10px 0 20px',
                        color: 'black',
                        display: { xs: 'none', sm: 'none', md: 'block' },
                    }}
                >
                    {appName}
                </Typography>
                {/* Mobile menu */}
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <HamburgerMenuButton handleOpenNavMenu={handleOpenNavMenu} />
                    <MobileMenu
                        anchorElNav={anchorElNav}
                        pages={pages}
                        handleNavMenuItem={handleNavMenuItem}
                        handleCloseNavMenu={handleCloseNavMenu}
                    />
                </Box>
                {/* Desktop menu */}
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    <DesktopMenu pages={pages} handleNavMenuItem={handleNavMenuItem} />
                </Box>

                {/* User settings */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'row', md: 'row', sm: 'row' },
                        alignItems: 'center',
                    }}
                >
                    <AvatarWithTooltip
                        userName={userName}
                        userPicture={userPicture}
                        handleOpenUserMenu={handleOpenUserMenu}
                    />
                    {userName && (
                        <UserMenu
                            anchorElUser={anchorElUser}
                            handleCloseUserMenu={handleCloseUserMenu}
                            handleLogout={handleLogout}
                        />
                    )}
                </Box>
                <Typography
                    variant='body1'
                    textAlign='center'
                    sx={{
                        padding: '3px 10px 0 10px',
                        display: { xs: 'none', md: 'flex', sm: 'flex' },
                        color: 'black',
                    }}
                >
                    {userName}
                </Typography>
            </Toolbar>
        </AppBar>
    );
};
