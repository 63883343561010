import { useState, useEffect } from 'react';

export const useVisibilityTimeout = (delay: number, ...deps: unknown[]) => {
    const [isVisible, setIsVisible] = useState(true);

    // In case we want to hide a component before the time-out
    const hide = () => {
        setIsVisible(false);
    };

    useEffect(
        () => {
            const timeId = setTimeout(() => {
                setIsVisible(false);
            }, delay);
            return () => {
                clearTimeout(timeId);
                setIsVisible(true);
            };
        },
        /* eslint-disable react-hooks/exhaustive-deps */
        [delay, ...deps]
    );

    return { isVisible, hide };
};
