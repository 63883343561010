import React from 'react';
import { MenuItem } from '@mui/material';

interface DesktopMenuProps {
    pages: {
        name: string;
        path: string;
    }[];
    handleNavMenuItem: (path: string) => void;
}
export const DesktopMenu = ({ pages, handleNavMenuItem }: DesktopMenuProps) => {
    return (
        <>
            {pages.map((page) => (
                <MenuItem
                    key={page.name}
                    onClick={() => handleNavMenuItem(page.path)}
                    sx={{ my: 2, color: 'black', display: 'block' }}
                >
                    {page.name}
                </MenuItem>
            ))}
        </>
    );
};
