import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { PortalFeedbackLinkButton } from '../Buttons/PortalFeedbackLinkButton';
import styles from './error.module.scss';

interface ErrorProps {
    errorMsg?: string;
}

export const Error = ({ errorMsg }: ErrorProps) => {
    return (
        <Alert severity='error' className='error'>
            <AlertTitle>Something went wrong</AlertTitle>
            {errorMsg && (
                <p className={styles.errorMsg}>
                    <b>{errorMsg}</b>
                </p>
            )}
            <p className={styles.errorText}>Please report your issue on the Slack channel:</p>
            <PortalFeedbackLinkButton />
        </Alert>
    );
};
