import React from 'react';
import { Header } from '../../Header/Header';
import styles from './page-template.module.scss';

type PageTemplateProps = {
    headerText: string;
    hasBackButton: boolean;
    children: React.ReactNode;
};

export const PageTemplate: React.FC<PageTemplateProps> = ({ headerText, hasBackButton, children }) => {
    return (
        <section className={styles.page}>
            <div className={styles.page__content}>
                <Header text={headerText} hasBackButton={hasBackButton} />
                <div className={styles.page__children}>{children}</div>
            </div>
        </section>
    );
};
