import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { notificationUpdated } from './notifications';
import { redirectLinkUpdated } from './redirect';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';

const initialState = {
    userDetails: null as null | UserInfo,
    isLoading: false,
    isLoadingUserRoles: false,
    hasError: false,
    hasUserRolesError: false,
};

type State = typeof initialState;

const userDetailsSlice = createSlice({
    name: 'userDetails',
    initialState,
    reducers: {
        userDetailsLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        userRolesLoading(state) {
            return {
                ...state,
                isLoadingUserRoles: true,
                hasUserRolesError: false,
            };
        },
        userDetailsReceived(state, action: PayloadAction<UserInfo>) {
            return {
                ...state,
                userDetails: action.payload,
                isLoading: false,
            };
        },
        userRolesUpdated(state, action: PayloadAction<UserInfo>) {
            return {
                ...state,
                userDetails: action.payload,
                isLoadingUserRoles: false,
            };
        },
        userDetailsError(state) {
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        },
        userRolesError(state) {
            return {
                ...state,
                isLoadingUserRoles: false,
                hasUserRolesError: true,
            };
        },
        userRolesErrorCleared(state) {
            return {
                ...state,
                hasUserRolesError: false,
            };
        },
    },
});

export default userDetailsSlice.reducer;

const {
    userDetailsLoading,
    userRolesLoading,
    userDetailsReceived,
    userRolesUpdated,
    userDetailsError,
    userRolesError,
} = userDetailsSlice.actions;

export const { userRolesErrorCleared: clearUserRolesError } = userDetailsSlice.actions;

export const getUserDetails = (userUid: string) => async (dispatch: Dispatch) => {
    dispatch(userDetailsLoading());
    try {
        const res = await getGatewayApiInstance().get(`/auth/users/${userUid}`);
        dispatch(userDetailsReceived(res.data));
    } catch (err: unknown) {
        dispatch(userDetailsError());
        handleAxiosErrors(err);
    }
};

export const editUserRoles =
    ({ newUserRoles, userUid }: { newUserRoles: UserRoles; userUid: string }) =>
    async (dispatch: Dispatch) => {
        dispatch(userRolesLoading());
        try {
            const roles = { roles: newUserRoles };
            const res = await getGatewayApiInstance().put(`/auth/users/${userUid}/roles`, roles);
            dispatch(userRolesUpdated(res.data));
            dispatch(redirectLinkUpdated(`/users`));
            dispatch(notificationUpdated('User roles were successfully updated'));
        } catch (err: unknown) {
            dispatch(userRolesError());
            handleAxiosErrors(err);
        }
    };

// SELECTORS
// Data
export const selectUser = (state: State): null | UserInfo => state.userDetails;
export const selectUserRoles = (state: State): null | UserRoles => state.userDetails?.customClaims?.roles || null;
export const selectUserDisplayName = (state: State): string => state.userDetails?.displayName || '';
// Loading
export const selectIsLoadingUserDetails = (state: State): boolean => state.isLoading;
export const selectIsLoadingUserRoles = (state: State): boolean => state.isLoadingUserRoles;
// Errors
export const selectHasUserDetailsError = (state: State): boolean => state.hasError;
export const selectHasUserRolesError = (state: State): boolean => state.hasUserRolesError;
