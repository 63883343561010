import React from 'react';
import { useAppSelector } from '../../../redux/typed-hooks';
import { selectUserDisplayName } from '../../../redux/reducers';
import { Error } from '../../Error/Error';
import { Form, Fieldset } from '../../Form';
import { SwitchInput } from '../../Form/Inputs';
import { SubmitButton } from '../../Buttons/SubmitButton';
import { Heading } from '../../Heading/Heading';
import { useEditUserRolesForm } from './useEditUserRolesForm';
import styles from './editUserRolesForm.module.scss';

interface EditUserRolesFormProps {
    userRoles: UserRoles | null;
}
export const EditUserRolesForm = ({ userRoles }: EditUserRolesFormProps) => {
    const {
        userManagementAdmin,
        financeViewer,
        financeManager,
        financeAdmin,
        partnershipsViewer,
        partnershipsManager,
        partnershipsAdmin,
        handleSubmit,
        isLoading,
        hasError,
    } = useEditUserRolesForm(userRoles);
    const userName = useAppSelector(selectUserDisplayName);

    return (
        <>
            <div className={styles.saveBtnWrapper}>
                <SubmitButton variant='save' isLoading={isLoading} formId='edit-userRoles-form' />
            </div>
            {hasError && (
                <div className={styles.errorWrapper}>
                    <Error />
                </div>
            )}
            <Heading level={2} text={userName} />
            {!userRoles && <p>This user has no roles yet. Set the roles below and Save your settings.</p>}
            <Form id='edit-userRoles-form' handleSubmit={handleSubmit}>
                <div className={styles.rolesWrapper}>
                    <Fieldset legend='Partnerships portal'>
                        <SwitchInput
                            checked={partnershipsViewer.value}
                            handleChange={partnershipsViewer.handleChange}
                            label='Viewer'
                        />
                        <SwitchInput
                            checked={partnershipsManager.value}
                            handleChange={partnershipsManager.handleChange}
                            label='Manager'
                        />
                        <SwitchInput
                            checked={partnershipsAdmin.value}
                            handleChange={partnershipsAdmin.handleChange}
                            label='Admin'
                        />
                    </Fieldset>
                    <Fieldset legend='Finance portal'>
                        <SwitchInput
                            checked={financeViewer.value}
                            handleChange={financeViewer.handleChange}
                            label='Viewer'
                        />
                        <SwitchInput
                            checked={financeManager.value}
                            handleChange={financeManager.handleChange}
                            label='Manager'
                        />
                        <SwitchInput
                            checked={financeAdmin.value}
                            handleChange={financeAdmin.handleChange}
                            label='Admin'
                        />
                    </Fieldset>

                    <Fieldset legend='Admin portal'>
                        <SwitchInput
                            checked={userManagementAdmin.value}
                            handleChange={userManagementAdmin.handleChange}
                            label='Admin'
                        />
                    </Fieldset>
                </div>
            </Form>
        </>
    );
};
