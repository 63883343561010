import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../redux/typed-hooks';
import { selectRedirectLink } from '../redux/reducers';
import { clearRedirectLink } from '../redux/reducers/redirect';

// Redirect on successful API call
export const useRedirect = () => {
    const redirectLink = useAppSelector(selectRedirectLink);
    const firstRender = useRef(true);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        // Clear the redirect link on first render
        if (firstRender.current) {
            dispatch(clearRedirectLink());
            firstRender.current = false;
            return;
        }
        navigate(redirectLink);
    }, [dispatch, navigate, redirectLink]);
};

/*
  USAGE: 
  1. Dispatch an action to update the redirect link in the action creator 
  2. Import and call the hook in the component that does the redirecting
 */
