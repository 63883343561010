import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    redirectLink: '',
};

type State = typeof initialState;

const redirectSlice = createSlice({
    name: 'redirect',
    initialState,
    reducers: {
        redirectLinkUpdated(state, action: PayloadAction<string>) {
            return {
                ...state,
                redirectLink: action.payload,
            };
        },
        redirectLinkCleared(state) {
            return {
                ...state,
                redirectLink: '',
            };
        },
    },
});

export default redirectSlice.reducer;

export const { redirectLinkUpdated, redirectLinkCleared: clearRedirectLink } = redirectSlice.actions;

// SELECTORS
export const selectRedirectLink = (state: State): string => state.redirectLink;
