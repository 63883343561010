import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/typed-hooks';
import { selectHasUserDetailsError, selectIsLoadingUserDetails, selectUserRoles } from '../../redux/reducers';
import { getUserDetails } from '../../redux/reducers/userDetails';
import { Loader } from '../Loader/Loader';
import { Error } from '../Error/Error';
import { EditUserRolesForm } from './subComponents/EditUserRolesForm';

export const EditUserRoles = () => {
    const { userUid } = useParams();
    const dispatch = useAppDispatch();

    const userRoles = useAppSelector(selectUserRoles);
    const isLoading = useAppSelector(selectIsLoadingUserDetails);
    const hasError = useAppSelector(selectHasUserDetailsError);

    useEffect(() => {
        if (userUid) {
            dispatch(getUserDetails(userUid));
        }
    }, [dispatch, userUid]);

    if (isLoading) return <Loader />;
    if (hasError) return <Error />;
    return <EditUserRolesForm userRoles={userRoles} />;
};
