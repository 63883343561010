import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/typed-hooks';
import { selectHasUserRolesError, selectIsLoadingUserRoles } from '../../../redux/reducers';
import { editUserRoles, clearUserRolesError } from '../../../redux/reducers/userDetails';
import { useSwitchInput } from '../../Form/Inputs';
import { useRedirect } from '../../../hooks';

export const useEditUserRolesForm = (userRoles: UserRoles | null) => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoadingUserRoles);
    const hasError = useAppSelector(selectHasUserRolesError);

    const financeViewer = useSwitchInput(userRoles?.finance.viewer || false);
    const financeManager = useSwitchInput(userRoles?.finance.manager || false);
    const financeAdmin = useSwitchInput(userRoles?.finance.admin || false);
    const partnershipsViewer = useSwitchInput(userRoles?.partnerships.viewer || false);
    const partnershipsManager = useSwitchInput(userRoles?.partnerships.manager || false);
    const partnershipsAdmin = useSwitchInput(userRoles?.partnerships.admin || false);
    const userManagementAdmin = useSwitchInput(userRoles?.user_management?.admin || false);

    const { userUid } = useParams();

    // Redirect on successful API call
    useRedirect();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newUserRoles: UserRoles = {
            finance: {
                viewer: financeViewer.value,
                manager: financeManager.value,
                admin: financeAdmin.value,
            },
            partnerships: {
                viewer: partnershipsViewer.value,
                manager: partnershipsManager.value,
                admin: partnershipsAdmin.value,
            },
            operations: {
                viewer: partnershipsViewer.value,
                manager: partnershipsManager.value,
                admin: partnershipsAdmin.value,
            },
            user_management: {
                admin: userManagementAdmin.value,
            },
        };

        if (userUid) {
            dispatch(editUserRoles({ newUserRoles, userUid }));
        }
    };

    useEffect(() => {
        dispatch(clearUserRolesError());
    }, [dispatch]);

    return {
        userManagementAdmin,
        financeViewer,
        financeManager,
        financeAdmin,
        partnershipsViewer,
        partnershipsManager,
        partnershipsAdmin,
        handleSubmit,
        isLoading,
        hasError,
    };
};
