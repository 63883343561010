import { ChangeEvent, useState } from 'react';

export const useSwitchInput = (initialValue: boolean) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.checked);
    };

    return { value, handleChange };
};
