import React from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';

interface MobileMenuProps {
    anchorElNav: HTMLButtonElement | null;
    pages: {
        name: string;
        path: string;
    }[];
    handleNavMenuItem: (path: string) => void;
    handleCloseNavMenu: () => void;
}

export const MobileMenu = ({ anchorElNav, pages, handleNavMenuItem, handleCloseNavMenu }: MobileMenuProps) => {
    return (
        <Menu
            id='menu-appbar'
            anchorEl={anchorElNav}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
                display: { xs: 'block', md: 'none' },
            }}
        >
            {pages.map((page) => (
                <MenuItem key={page.name} onClick={() => handleNavMenuItem(page.path)}>
                    <Typography textAlign='center'>{page.name}</Typography>
                </MenuItem>
            ))}
        </Menu>
    );
};
