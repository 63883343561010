import React from 'react';
import { Button } from '@mui/material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

export const PortalFeedbackLinkButton = () => (
    <Button
        target='_blank'
        variant='outlined'
        size='large'
        href='https://naduvi.slack.com/archives/C038J2A2H5Z'
        endIcon={<InsertLinkIcon />}
    >
        #portal-feedback
    </Button>
);
