import * as React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useAppSelector } from './redux/typed-hooks';
import { selectUserHasAdminRights, selectUserHasNoRights } from './redux/reducers';
import { NavBar } from './components/NavBar/NavBar';
import { SuccessNotification } from './components/SuccessNotification/SuccessNotification';
import { EditUserRolesPage, LoginPage, UnauthorizedPage, UsersOverviewPage } from './pages/index';
import styles from './app.module.scss';

const RequireAuth = ({
    isAuthorized,
    hasNoRights,
    children,
}: {
    isAuthorized: boolean;
    hasNoRights: boolean;
    children: JSX.Element;
}) => {
    if (hasNoRights) return <UnauthorizedPage />;
    if (isAuthorized) return children;
    return <Navigate to='/login' />;
};

export const App = () => {
    const hasAdminRights = useAppSelector(selectUserHasAdminRights);
    const hasNoRights = useAppSelector(selectUserHasNoRights);

    return (
        <>
            <div className={styles.notifications}>
                <SuccessNotification />
            </div>
            <NavBar appName='Admin' landingPage='users' pages={[{ name: 'Users', path: '/users' }]} />
            <Routes>
                <Route path='/login' element={<LoginPage />} />
                <Route
                    path='/'
                    element={
                        <RequireAuth isAuthorized={hasAdminRights} hasNoRights={hasNoRights}>
                            <UsersOverviewPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/users'
                    element={
                        <RequireAuth isAuthorized={hasAdminRights} hasNoRights={hasNoRights}>
                            <UsersOverviewPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/users/:userUid'
                    element={
                        <RequireAuth isAuthorized={hasAdminRights} hasNoRights={hasNoRights}>
                            <EditUserRolesPage />
                        </RequireAuth>
                    }
                />
            </Routes>
        </>
    );
};
