import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/typed-hooks';
import { selectUserName, selectUserPicture } from '../../redux/reducers';
import { logout } from '../../redux/reducers/auth';

export const useNavBar = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userName = useAppSelector(selectUserName);
    const userPicture = useAppSelector(selectUserPicture);

    const [anchorElNav, setAnchorElNav] = useState<HTMLButtonElement | null>(null);
    const [anchorElUser, setAnchorElUser] = useState<HTMLButtonElement | null>(null);

    const handleOpenNavMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElNav(e.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleNavMenuItem = (path: string) => {
        navigate(path);
        handleCloseNavMenu();
    };

    const handleOpenUserMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElUser(e.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        handleCloseUserMenu();
        dispatch(logout());
    };

    return {
        userName,
        userPicture,
        anchorElNav,
        anchorElUser,
        handleOpenNavMenu,
        handleNavMenuItem,
        handleCloseNavMenu,
        handleOpenUserMenu,
        handleCloseUserMenu,
        handleLogout,
    };
};
