import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

export const BackButton = () => {
    const navigate = useNavigate();

    return (
        <Button type='button' variant='outlined' onClick={() => navigate(-1)}>
            Back
        </Button>
    );
};
