import * as React from 'react';
import { ChangeEvent } from 'react';
import { Switch } from '@mui/material';
import styles from './switchInput.module.scss';

interface SwitchInputProps {
    checked: boolean | undefined;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    label: string;
}

export const SwitchInput = ({ checked, handleChange, label }: SwitchInputProps) => {
    return (
        <div className={styles.switchWrapper}>
            <p>{label}</p>
            <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
        </div>
    );
};
