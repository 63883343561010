import React from 'react';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

interface HamburgerMenuButtonProps {
    handleOpenNavMenu: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const HamburgerMenuButton = ({ handleOpenNavMenu }: HamburgerMenuButtonProps) => {
    return (
        <IconButton
            size='large'
            aria-label='User'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={handleOpenNavMenu}
            sx={{ p: '0px 10px 0px 0px', marginLeft: '10px' }}
        >
            <MenuIcon />
        </IconButton>
    );
};
