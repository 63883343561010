import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';

const initialState = {
    users: [] as Users,
    isLoading: false,
    hasError: false,
};

type State = typeof initialState;

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        usersLoading(state: State) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        usersReceived(state: State, action: PayloadAction<Users>) {
            return {
                ...state,
                users: action.payload,
                isLoading: false,
            };
        },
        usersError(state: State) {
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        },
    },
});

export default usersSlice.reducer;

const { usersLoading, usersReceived, usersError } = usersSlice.actions;

export const getUsers = () => async (dispatch: Dispatch) => {
    dispatch(usersLoading());
    try {
        const res = await getGatewayApiInstance().get(`/auth/users`);
        dispatch(usersReceived(res.data.users));
    } catch (err: unknown) {
        dispatch(usersError());
        handleAxiosErrors(err);
    }
};

// SELECTORS
export const selectUsers = (state: State): Users => state.users;
export const selectAreUsersLoading = (state: State): boolean => state.isLoading;
export const selectHasUsersError = (state: State): boolean => state.hasError;
