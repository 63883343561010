import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { PageTemplate } from '../../components/templates/PageTemplate/PageTemplate';
import { PortalFeedbackLinkButton } from '../../components/Buttons/PortalFeedbackLinkButton';
import styles from './unauthorized.module.scss';

export const UnauthorizedPage = () => {
    return (
        <PageTemplate headerText='Unauthorized' hasBackButton={false}>
            <Alert severity='info'>
                <AlertTitle>You do not have sufficient rights to access the Portal</AlertTitle>
                <p className={styles.text}>Please report your issue on the Slack channel:</p>
                <PortalFeedbackLinkButton />
            </Alert>
        </PageTemplate>
    );
};
