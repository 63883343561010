import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';

// Setting up Redux store to manage state changes
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

// Infer the root state type from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Export a hook that resolves dispatch types
export type AppDispatch = typeof store.dispatch;
