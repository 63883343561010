import React from 'react';
import Button from '@mui/material/Button';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { useAppSelector } from '../../redux/typed-hooks';
import { selectSuccessMsg } from '../../redux/reducers';
import { useVisibilityTimeout } from '../../hooks';
import styles from './successNotification.module.scss';

export const SuccessNotification = () => {
    const successMsg = useAppSelector(selectSuccessMsg);

    const { isVisible, hide } = useVisibilityTimeout(4000, successMsg.id);

    if (!isVisible || !successMsg.msg) return null;

    return (
        <div className={styles.successNotification}>
            <div className={styles.successNotification__successIcon}>
                <CheckCircleOutlineOutlinedIcon color='primary' fontSize='medium' />
            </div>
            <p>
                <span className={styles.successNotification__heading}>
                    <b>Success</b>
                </span>
                <br />
                {successMsg.msg}
            </p>
            <div className={styles.successNotification__closeBtn}>
                <Button variant='text' onClick={hide}>
                    Close
                </Button>
            </div>
        </div>
    );
};
