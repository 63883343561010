import React from 'react';
import { CircularProgress } from '@mui/material/';
import styles from './loader.module.scss';

export const Loader = () => {
    return (
        <div className={styles.loader}>
            <div>
                <CircularProgress />
            </div>
            <i>Loading</i>
        </div>
    );
};
