import { combineReducers } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import authReducer, * as fromAuthReducer from './auth';
import usersReducer, * as fromUsersReducer from './users';
import redirectReducer, * as fromRedirectReducer from './redirect'
import userDetailsReducer, * as fromUserDetailsReducer from './userDetails'
import notificationsReducer, * as fromNotificationsReducer from './notifications'

export default combineReducers({
    authReducer,
    usersReducer,
    redirectReducer,
    userDetailsReducer,
    notificationsReducer
});

// GLOBAL SELECTORS

// Auth
export const selectUserName = (state: RootState): string => fromAuthReducer.selectUserName(state.authReducer);
export const selectUserPicture = (state: RootState): string => fromAuthReducer.selectUserPicture(state.authReducer);
export const selectUserHasAdminRights = (state: RootState): boolean => fromAuthReducer.selectUserHasAdminRights(state.authReducer);
export const selectUserHasNoRights = (state: RootState): boolean => fromAuthReducer.selectUserHasNoRights(state.authReducer);
export const selectHasLoginError = (state: RootState): boolean => fromAuthReducer.selectHasLoginError(state.authReducer);

// Users
export const selectUsers = (state: RootState): Users => fromUsersReducer.selectUsers(state.usersReducer);
export const selectAreUsersLoading = (state: RootState): boolean => fromUsersReducer.selectAreUsersLoading(state.usersReducer);
export const selectHasUsersError = (state: RootState): boolean => fromUsersReducer.selectHasUsersError(state.usersReducer);

// User details
export const selectUser = (state: RootState): null | UserInfo => fromUserDetailsReducer.selectUser(state.userDetailsReducer);
export const selectUserRoles = (state: RootState): null | UserRoles => fromUserDetailsReducer.selectUserRoles(state.userDetailsReducer);
export const selectUserDisplayName = (state: RootState): string => fromUserDetailsReducer.selectUserDisplayName(state.userDetailsReducer);
export const selectIsLoadingUserDetails= (state: RootState): boolean => fromUserDetailsReducer.selectIsLoadingUserDetails(state.userDetailsReducer);
export const selectIsLoadingUserRoles = (state: RootState): boolean => fromUserDetailsReducer.selectIsLoadingUserRoles(state.userDetailsReducer);
export const selectHasUserDetailsError = (state: RootState): boolean => fromUserDetailsReducer.selectHasUserDetailsError(state.userDetailsReducer);
export const selectHasUserRolesError = (state: RootState): boolean => fromUserDetailsReducer.selectHasUserRolesError(state.userDetailsReducer);

// Redirect
export const selectRedirectLink = (state: RootState): string => fromRedirectReducer.selectRedirectLink(state.redirectReducer);

// Success notification
export const selectSuccessMsg = (state: RootState): SuccessMsg => fromNotificationsReducer.selectSuccessMsg(state.notificationsReducer);
