import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    DataGrid,
    GridColDef,
    GridInitialState,
    GridValueFormatterParams,
    GridRowParams,
    MuiEvent,
    GridValueGetterParams,
} from '@mui/x-data-grid';
import { LinearProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useAppDispatch, useAppSelector } from '../../redux/typed-hooks';
import { selectAreUsersLoading, selectHasUsersError, selectUsers } from '../../redux/reducers';
import { getUsers } from '../../redux/reducers/users';
import { Error } from '../Error/Error';
import styles from './usersOverview.module.scss';

export const UsersOverview = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const users = useAppSelector(selectUsers);
    const isLoading = useAppSelector(selectAreUsersLoading);
    const hasError = useAppSelector(selectHasUsersError);

    const INITIAL_PAGE_SIZE = 10;

    const columns: GridColDef[] = [
        {
            field: 'uid',
            headerName: 'uid',
            flex: 1,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'displayName',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'E-mail',
            flex: 1,
        },
        {
            field: 'metadata',
            headerName: 'Last sign in time',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams<UserInfo['metadata']>) => {
                if (!params.value.lastSignInTime) return '';
                return params.value.lastSignInTime;
            },
        },
        {
            field: 'partnerships',
            headerName: 'Partnerships',
            flex: 1,
            valueGetter: (params: GridValueGetterParams<UserInfo['customClaims']>) => {
                const { customClaims } = params.row;
                if (customClaims?.roles?.partnerships?.admin) return 'Admin';
                if (customClaims?.roles?.partnerships?.manager) return 'Manager';
                if (customClaims?.roles?.partnerships?.viewer) return 'Viewer';

                return 'No roles';
            },
        },
        {
            field: 'finance',
            headerName: 'Finance',
            flex: 1,
            valueGetter: (params: GridValueGetterParams<UserInfo['customClaims']>) => {
                const { customClaims } = params.row;
                if (customClaims?.roles?.finance?.admin) return 'Admin';
                if (customClaims?.roles?.finance?.manager) return 'Manager';
                if (customClaims?.roles?.finance?.viewer) return 'Viewer';

                return 'No roles';
            },
        },
        {
            field: 'user_management',
            headerName: 'User management',
            flex: 1,
            valueGetter: (params: GridValueGetterParams<UserInfo['customClaims']>) => {
                const { customClaims } = params.row;
                if (customClaims?.roles?.user_management?.admin) return 'Admin';
                return 'No role';
            },
        },
    ];

    const initialDataGridState: GridInitialState = {
        sorting: {
            sortModel: [{ field: 'uid', sort: 'asc' }],
        },
    };

    const handleRowClick = (params: GridRowParams, e: MuiEvent<React.MouseEvent<HTMLElement>>) => {
        e.defaultMuiPrevented = true;
        navigate(`/users/${params.row.uid}`);
    };

    const [searchValue, setSearchValue] = useState<string>('');
    const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const filteredUsers = useMemo<Users>(() => {
        return users.filter((user) => {
            if (!searchValue) return true;

            return (
                user?.email?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                user?.displayName?.toLocaleLowerCase()?.includes(searchValue.toLocaleLowerCase())
            );
        });
    }, [users, searchValue]);

    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch]);

    if (hasError) return <Error />;
    return (
        <div className={styles.wrapper}>
            <TextField
                value={searchValue}
                onChange={onSearchValueChange}
                label='Search'
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />

            <DataGrid
                rows={filteredUsers}
                columns={columns}
                pageSize={10}
                getRowId={(row) => row.uid}
                onRowClick={handleRowClick}
                loading={isLoading}
                initialState={initialDataGridState}
                rowsPerPageOptions={[INITIAL_PAGE_SIZE, 25, 50, 100]}
                autoHeight
                sx={{ cursor: 'pointer' }}
                components={{
                    LoadingOverlay: LinearProgress,
                }}
                componentsProps={{
                    noRowsOverlay: { text: 'No users to show!' },
                }}
            />
        </div>
    );
};
