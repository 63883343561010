import React from 'react';
import { BackButton } from '../Buttons/BackButton';
import { Heading } from '../Heading/Heading';
import styles from './header.module.scss';

type HeaderProps = {
    text: string;
    hasBackButton: boolean;
};

export const Header = ({ text, hasBackButton }: HeaderProps) => {
    return (
        <>
            <header className={styles.header}>
                <Heading level={1} text={text} />
                {hasBackButton ? <BackButton /> : ''}
            </header>
            <hr className={styles.hr} />
        </>
    );
};
