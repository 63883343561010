import React from 'react';
import { capitalize } from '../../utils/formatting/formatting';
import styles from './heading.module.scss';

interface HeadingProps {
    text: string;
    level: 1 | 2 | 3;
}

export const Heading = ({ text, level }: HeadingProps) => {
    if (level === 2) return <h2 className={styles.t2}>{text}</h2>;
    if (level === 3) return <h3 className={styles.t3}>{text}</h3>;
    return <h1 className={styles.t1}>{capitalize(text)}</h1>;
};
