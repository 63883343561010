import React from 'react';
import Avatar from '@mui/material/Avatar';
import { IconButton, Tooltip } from '@mui/material';

interface AvatarWithTooltipProps {
    userName: string;
    userPicture: string;
    handleOpenUserMenu: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const AvatarWithTooltip = ({ userName, userPicture, handleOpenUserMenu }: AvatarWithTooltipProps) => {
    return (
        <Tooltip title={userName ? `Logged in as ${userName}` : 'Not logged in'}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={userName} src={userPicture} />
            </IconButton>
        </Tooltip>
    );
};
